import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import { scrollToTop } from "../../../components/ScrollTop";
import React from "react";
import Stepper from "@mui/material/Stepper";
import { makeStyles } from "@mui/styles";
import { alpha } from "@mui/material/styles";
import { AlertChip, InfoChip } from "@armus/armus-dashboard";
import Container from "../../../../data/Container";
import MeasuresMetaStore from "../../../../data/submit/MeasuresMetaStore";
import MeasuresDraftStore from "../../../../data/submit/MeasuresDraftStore";
import MeasuresScoreStore from "../../../../data/submit/MeasuresScoreStore";

const useStyles = makeStyles((theme) => ({
    stepper: {
        paddingLeft: 0,
        paddingRight: 0,
        marginLeft: theme.spacing(-1),
        marginRight: theme.spacing(-1),
        margin: 0,
        "& .MuiStepLabel-label": {
            fontSize: 20
        },
        "& .MuiStepperActive-root .MuiStepButton-root": {
            // background: theme.palette.background.default,
            // borderRadius: 10,
            // borderBottomLeftRadius: 0,
            // borderBottomRightRadius: 0,
            borderBottom: "3px solid " + theme.palette.primary.main,
            zIndex: 4,
            transition: "all .5s"
        },
        "& .MuiStepButton-root": {
            //height: 112,
            paddingBottom: theme.spacing(4),
            whiteSpace: "nowrap",
            borderBottom: "3px solid " + theme.palette.background.default,
            zIndex: 3,
            transition: "all .5s",
            "& .MuiStepLabel-active": {
                color: theme.palette.primary.main,
                transition: "all .2s"
            },
            "& .MuiStepIcon-root": {
                marginTop: 1,
                transition: "all .2s"
            },
            "& .MuiStepIcon-active": {
                zIndex: 3,
                transform: "scale(2)"
            }
        },
        "& .MuiStepConnector-root": {
            zIndex: 2
        },
        "& .MuiStepConnector-line, & .MuiStepIcon-root, & .MuiStepLabel-label": {
            transition: "all .2s"
        },
        "& .Mui-disabled": {
            color: alpha(theme.palette.text.primary, .5),
            transition: "all .2s",
            "& .MuiStepConnector-line": {
                borderColor: alpha(theme.palette.text.primary, .15)
            },
            "& .MuiStepIcon-root, & .MuiStepLabel-label, & .MuiStepLabel-label": {
                color: alpha(theme.palette.text.primary, .5) + "!important"
            }
        }
    },
    scrollToTop: {
        position: 'fixed',
        top: theme.spacing(3),
        right: theme.spacing(4),
        zIndex: 3
    },
    stepAlertChipContainer: {
        position: "absolute",
        left: 0,
        paddingTop: 4,
        width: "100%",
        textAlign: "center",
        "& .MuiChip-root": {
            height: "18px !important"
        }
    },
    alertChip: {
        marginRight: 2,
        height: 19
    }
}));


const StepBar = ({
    measureSetProxies,
    currentStep,
    onStepChange
}) => {
    const classes = useStyles();
    return (
        <Stepper
            alternativeLabel
            className={classes.stepper}
            nonLinear
            activeStep={currentStep}
        >
            {measureSetProxies.map((measureSetProxy, index) => {
                const messageTypeCounts = (
                    <div className={classes.stepAlertChipContainer}>
                        {measureSetProxy.getMessageCounts().filter((it) => it.count > 0).map((it) => (
                            <AlertChip
                                key={it.type}
                                classNames={classes.alertChip}
                                label={it.count}
                                severity={it.type}
                                size={"small"}
                            />
                        ))}
                        {measureSetProxy.id === "review" ? null : measureSetProxy.isIncluded() ?
                            <InfoChip size="xs" color={"success"} label={"Included"} variant={"outlined"} /> : <InfoChip size="xs" color={"default"} label={"Excluded"} disabled variant={"outlined"}/>
                        }
                    </div>
                );
                const selectedMeasuresCount = measureSetProxy.getSelectedMeasuresCount();
                return (
                    <Step
                        key={measureSetProxy.id}
                        disabled={measureSetProxy.id === "review" && !selectedMeasuresCount}
                        className={currentStep === index ? "MuiStepperActive-root" : ""}
                    >
                        <StepButton
                            onClick={() => {scrollToTop(); onStepChange(measureSetProxy.id);}}
                            optional={(
                                <React.Fragment>
                                    Score Preview: <strong>{measureSetProxy.getScore()}</strong> / {measureSetProxy.getMaxScore()}<br/>
                                    {measureSetProxy.id !== "review" && <>Weight: {measureSetProxy.getWeight()}%<br/></>}
                                    Measures Selected: {selectedMeasuresCount}
                                    {messageTypeCounts}
                                    {measureSetProxy.id === "review" && <><br/> &nbsp;</>}
                                </React.Fragment>
                            )}
                        >
                            {measureSetProxy.title()}
                        </StepButton>
                    </Step>
                );
            })}
        </Stepper>
    );
};

export default Container(
    StepBar,
    () => [
        MeasuresMetaStore,
        MeasuresDraftStore,
        MeasuresScoreStore
    ],
    (state, props) => props,
    {pure: false}
);
