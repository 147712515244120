import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import Typography from '@mui/material/Typography';
import {formatMessage, FormattedMessage, Paper, Main, Spinner} from "@armus/armus-dashboard";
import Container from "../../../data/Container";
import NPIStore from "../../../data/submit/NPIStore";
import UserStore from "../../../data/UserStore";
import MeasuresMetaStore from "../../../data/submit/MeasuresMetaStore";
import {buildUrl} from "../../../../routes";
import ErrorPage from "../../ErrorPage";
import {homeBreadcrumb} from "../Home/Home";
import EditMeasureSet from "./EditMeasureSet";
import {getOrCreateMeasureSetProxy} from "../../../data/submit/utils/MeasureSetProxy";
import Box from '@mui/material/Box';
import ScrollTop from "../../components/ScrollTop";
import {dispatchloadSubmitDraftData, dispatchOnMeasureSetChange, dispatchSubmit} from "../../../data/submit/MeasuresDraftActions";
import StepBar from "./components/StepBar";
import ReviewAndSubmit from "./ReviewAndSubmit";
import HasDRCF from "../../components/HasDRCF";

const useStyles = makeStyles((theme) => ({
    content: {
        height: "100%",
        overflow: "scroll"
    },
    paper: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        padding: 0
    },
    tinNpiContainer: {
        color: "#888",
        fontSize: "80%",
        whiteSpace: "nowrap",
        overflow: "hidden",
        width: "100%"
    },
    title: {
        marginTop: 0
    },
    section: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2)
    },
    sticky: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(2),
        paddingBottom: 0,
        top: 0,
        left: "auto",
        right: 0,
        position: "sticky",
        height: 223,
        zIndex: 4,
        whiteSpace: "nowrap"
    },
    scrollToTop: {
        position: 'fixed',
        top: theme.spacing(3),
        right: theme.spacing(4),
        zIndex: 3
    }
}));

export const draftBreadcrumb = (year, org = {}, impl = {}, npi = {}, type = "individual") => ({
    label: npi.tin ? formatMessage({
        id: "draft." + type + ".breadcrumb",
        defaultMessage: "TIN: {tin}"},
        npi
    ) : "...",
    url: buildUrl("DRAFT", {year, orgKey: org.key, implKey: impl.key, npiId: npi.id, type})
});

const Draft = ({
    isValidURL,
    year,
    organization,
    implementation,
    npi,
    yearInfo,
    setView,
    isLoading,
    onMeasureSetChange,
    onloadSubmitDraftData,
    measureSetProxies,
    onSubmit,
    submissionType
}) => {
    const classes = useStyles();
    const [step, setStep] = React.useState("quality");
    const stickyRef = React.useRef({});

    if((!isLoading && !isValidURL) || !npi) {
        // the url is invalid show 404 page.
        return <ErrorPage statusCode={404} />;
    }
    const breadcrumb = draftBreadcrumb(year, organization, implementation, npi, submissionType);
    let body = null;
    let spinner = null;
    if(isLoading) {
        spinner = (
            <Box p={5}>
                <Spinner />
            </Box>
        );
    }
    else {
        const measureSetProxy = measureSetProxies.find(it => it.id === step);
        const currentStep = measureSetProxies.indexOf(measureSetProxy);
        const npiLabel = npi.submitter.npiList.map(it => `${it.npi} ${it.name}`).join(", ");
        body = (
            <React.Fragment>
                <Box boxShadow={1} ref={stickyRef} className={classes.sticky}>
                    <div className={classes.tinNpiContainer}>
                        TIN: <strong>{npi.submitter.tin}</strong> &nbsp;&nbsp;&nbsp; NPI: <strong title={npiLabel}>{npiLabel}</strong>
                    </div>
                    <Typography className={classes.title} component="h1" variant="h4">
                        <FormattedMessage
                            id={"draft." + submissionType + ".title"}
                            defaultMessage={"{name}"}
                            values={npi}
                        />
                    </Typography>
                    <StepBar
                        measureSetProxies={measureSetProxies}
                        currentStep={currentStep}
                        onStepChange={(nextStep) => {
                            if(!measureSetProxy.isValid() && currentStep !== "review") {
                                // clear all measures
                                onMeasureSetChange(measureSetProxy.id, {measureValues: []});
                            }
                            if(nextStep === "review") {
                                onloadSubmitDraftData();
                            }
                            setStep(nextStep);
                        }}
                    />
                </Box>
                <div className={classes.section}>
                    {measureSetProxy.id === "review" ? (
                        <ReviewAndSubmit
                            key={measureSetProxy.id}
                            yearInfo={yearInfo}
                            stickyRef={stickyRef}
                            measureSetProxies={measureSetProxies}
                            measureSetProxy={measureSetProxy}
                            onMeasureSetChange={onMeasureSetChange}
                            onSubmit={onSubmit}
                        />
                    ) : (
                        <EditMeasureSet
                            yearInfo={yearInfo}
                            key={measureSetProxy.id}
                            stickyRef={stickyRef}
                            measureSetProxy={measureSetProxy}
                            onMeasureSetChange={onMeasureSetChange}
                        />
                    )}

                </div>
            </React.Fragment>
        );
    }

    return (
        <Main breadcrumbs={[{label: "Home", url: "/"}, homeBreadcrumb(year, organization, implementation), breadcrumb]} fullWidth={true} fullHeight={true}>
            <Box className={classes.content}>
                <Paper className={classes.paper}>
                    {spinner}
                    {body}
                    <ScrollTop className={classes.scrollToTop} />
                </Paper>
            </Box>
        </Main>
    );
};

Draft.propTypes = {
    isValidURL: PropTypes.bool.isRequired,
    year: PropTypes.string,
    organization: PropTypes.object,
    implementation: PropTypes.object,
    yearInfo: PropTypes.object,
    isLoading: PropTypes.bool,
    setView: PropTypes.func,
    onMeasureSetChange: PropTypes.func
};

const WrappedDraft = (props) => {
    return (
        <HasDRCF year={props.year} orgKey={props.organization.key} implKey={props.implementation.key}>
            <Draft {...props} />
        </HasDRCF>
    );
};

export default Container(
    WrappedDraft,
    () => [
        UserStore,
        NPIStore,
        MeasuresMetaStore
    ],
    (state, props) => {
        const params = props.match.params;
        const year = params.year;
        const orgKey = params.orgKey;
        const implKey = params.implKey;
        const npiId = params.npiId;
        const type = params.type;
        const org = UserStore.getOrganization(orgKey);
        const impl = UserStore.getImplementation(orgKey, implKey);
        const npi = NPIStore.getNPI(npiId);
        const isValidURL = !!org && !!impl;
        return {
            ...props,
            isValidURL,
            year,
            organization: org || {},
            implementation: impl || {},
            npi: npi || {},
            yearInfo: NPIStore.getYear(),
            isLoading: NPIStore.isLoading() || MeasuresMetaStore.isLoading(),
            onMeasureSetChange: dispatchOnMeasureSetChange,
            onloadSubmitDraftData: dispatchloadSubmitDraftData,
            onSubmit: dispatchSubmit,
            measureSetProxies: [
                getOrCreateMeasureSetProxy("quality"),
                getOrCreateMeasureSetProxy("pi"),
                getOrCreateMeasureSetProxy("ia"),
                getOrCreateMeasureSetProxy("review") // this is a fake category that wraps up all three categories into one.
            ],
            submissionType: type
        };
    }
);
